.header-color {
  background: yellow;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #bdc3c7, #2c3e50);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #bdc3c7, #2c3e50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid {
  background: 2c3e50;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #bdc3c7, #2c3e50);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #bdc3c7, #2c3e50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
}







.mdl-layout{
  overflow-y: hidden;


}
/* Responsive columns
@media  screen and (min-width: 320px) and (max-width: 710px) {
  .projects-card {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
} */

/* .project-card{
  float: left;
  width: 25%;
  padding: 0 10px;
}

.projects-grid {margin: 0 -5px;} */

/* Clear floats after the columns */
/* .projects-grid:after {
  content: "";
  display: table;
  clear: both;
} */

/* .projects-grid-mobile{
  display: inline;
  
} */

.avatar-img{

  height: 450px;
  padding-top: 6em;
}


@media screen and (min-width: 320px) and (max-width: 710px){
  .avatar-img{
    height: 300px;
    padding-top: 3em;
  }
  
}

.h1{
  margin: 0;
}

.avatar-cv{
 height: 350px;
 display: block;
 margin-left: auto;
 margin-right: auto;
}

@media screen and (min-width: 320px) and (max-width: 710px){
  .avatar-cv{
    height: 350px;
    padding-top: 6em;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

}


/*Desktop*/
.banner-text-desktop{
background-color: black;
opacity: .8;
width: 75%;  
margin: auto;
border-radius: 10px;
}

@media screen and (min-width: 320px) and (max-width: 710px){
  .banner-text-desktop{
    background-color: black;
    opacity: .8;
    width: 75%;  
    height:45%;
    margin: auto;
    border-radius: 10px;
  }
  
}


.banner-text-desktop h1{
font-size: 60px;
font-weight: bold;
color: white;

}

@media screen and (min-width: 320px) and (max-width: 710px){
  .banner-text-desktop h1{
    font-size: 40px;
    font-weight: bold;
    color: white;
  }
  
}


.banner-text-desktop hr{
border-top: 5px dotted white;
width: 50%;
margin: auto;
}

.banner-text-desktop p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

@media screen and (min-width: 320px) and (max-width: 710px){
  .banner-text-desktop p{
    color: white;
  font-size: 18px;
  padding: 1em;
}
  
  
}

  .cv-icons{
    display: flex;
    width: 50%;
    margin: 15px;
    
    }
    
    .cv-icons i{
    
      color: white;
    font-size: 3em;
    padding: 8px;
    }
    
  /*Desktop*/
.social-links-desktop {
display: flex;
justify-content: space-evenly;
width:60%;
margin: auto;

}

.social-links-desktop i {
  color: white;
  font-size: 5em;
padding: 8px;

}

@media screen and (min-width: 320px) and (max-width: 710px){
  .social-links-desktop i {
  color: white;
  font-size:4.5em;
  padding: 8px;
}

}




.contact-body{
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;

}
.contact-grid{
text-align: center;
justify-content: center;
width: 80%;
height: 550px;
background: white;
color: black;

margin-bottom: 20px;

}

.contact-grid h2 {
font-family: 'Anton', sans-serif;
}

.contact-grid p { 
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}
.contact-grid hr{
border-top: 5px dotted black;
width: 50%;
margin: auto;
}

.contact-list i{
  font-size: 60px;
  padding-right: 5rem;
  
}
.cv-right-col { 
background: #27221f;
color: white;
padding: 3em;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.moveUpDown{
  animation-name : mymove;
  animation-iteration-count : infinite;
  animation-direction : alternate;
  animation-duration : 1500ms;
}

@keyframes mymove {
  from {transform: translateY(0px);}
  to {transform: translateY(10px);}
}

.zoom-box {
  transition: transform .2s; /* Animation */
}
.zoom-box:hover {
  transform: scale(1.1);
}


.mdl-progress > .progressbar {
  background-color: #2c3e50 !important; 
  z-index: 1;
  left: 0;
}





